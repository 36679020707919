<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            businessUnitCheckFields: [
                {
                    name: 'user.name',
                    title: this.getI18n('USERS.TITLES', 'user'),
                    sortField: 'users|users.name',
                },
                {
                    name: 'business_unit.name',
                    title: this.getI18n(
                        'BUSINESS_UNITS.TITLES',
                        'business_unit'
                    ),
                    sortField: 'business_units|business_units.name',
                },
                {
                    name: 'point_of_sale.name',
                    title: this.getI18n('POS.TITLES', 'pos'),
                    sortField: 'point_of_sales|point_of_sales.name',
                },
                {
                    name: 'datetime_in',
                    title: this.getI18n('POS_CHECK', 'TITLES.checkin'),
                    sortField: 'datetime_in',
                    formatter: this.formatDateTime,
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'datetime_out',
                    title: this.getI18n('POS_CHECK', 'TITLES.checkout'),
                    sortField: 'datetime_out',
                    formatter: this.formatDateTime,
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'time_in_store',
                    title: this.getI18n('POS_CHECK', 'FIELDS.time_in_store'),
                    formatter: (value) => `${value} min`,
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'battery',
                    title: this.getI18n('POS_CHECK', 'FIELDS.battery'),
                },
            ],
        }
    },
}
</script>
