<script>
const i18nResponses = 'RESPONSES'
const i18nCommon = 'COMMON'

export default {
    data() {
        return {
            i18nResponses,
            i18nCommon
        }
    },
    methods: {
        operationTranslation(operation, modifier = 0) {
            return this.$options.filters.firstToLower(
                this.getI18nModified({
                    prefix: i18nResponses,
                    suffix: operation,
                    modifier: modifier,
                })
            )
        },
        feedback(title, text, icon) {
            this.$swal.fire({
                scrollbarPadding: false,
                title,
                text,
                icon,
            })
        },
        positiveFeedback(name, operation, modifier = 0) {
            const successfulOperation = `successfully_${operation}`
            this.$bvToast.toast(`${name} ${this.operationTranslation(successfulOperation, 0)}`,
            {
                title: name,
                variant: 'success'
            })
        },
        successFeedback(title, message) {
            this.$bvToast.toast(message,
            {
                title: title,
                variant: 'success'
            })
        },
        warningFeedback(title, message) {
            this.$bvToast.toast(message,
            {
                title: title,
                variant: 'warning'
            })
        },
        dangerFeedback(title, message) {
            this.$bvToast.toast(message,
            {
                title: title,
                variant: 'danger'
            })
        },
        warningFeedbackApi(title, object) {
            if (!object) {
                return
            }

            const messages = Object.entries(object)
            if (messages.length <= 0 || messages[0].length <= 1) {
                return
            }

            this.$bvToast.toast(messages[0][1],
            {
                title: title,
                variant: 'warning'
            })
        },
        operationUnsuccessful(prefix, suffix, operation = 'operation') {
            const message = this.i18nExists(prefix, suffix)
                ? this.getI18n(prefix, suffix)
                : this.getI18n('RESPONSES', `${operation}_not_possible`)

            this.negativeFeedback(
                this.getI18n('RESPONSES', 'something_wrong'),
                message
            )
        },
        negativeFeedback(title, text) {
            title = title || this.getI18n('RESPONSES', 'something_wrong')
            text = text || this.getI18n('RESPONSES', 'operation_not_possible')
            this.$swal.fire({
                scrollbarPadding: false,
                title,
                text,
                icon: 'error',
            })
        },
    },
}
</script>
