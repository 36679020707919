<script>
const i18nPagination = 'PAGINATION'
export default {
    methods: {
        i18nPagination() {
            return (
                this.getI18n(i18nPagination, 'showing') +
                ' {from} ' +
                this.getI18n(i18nPagination, 'to') +
                ' {to} ' +
                this.getI18nModified({
                    prefix: i18nPagination,
                    suffix: 'records',
                    modifier: 2,
                }) +
                ' ' +
                this.getI18n(i18nPagination, 'of') +
                ' {total} ' +
                this.getI18nModified({
                    prefix: i18nPagination,
                    suffix: 'records',
                    modifier: 2,
                })
            )
        },

        i18nNoRecords() {
            return this.getI18n(i18nPagination, 'no_records')
        },

        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        onPaginationData(onPaginationData) {
            this.$refs.pagination.setPaginationData(onPaginationData)
            this.$refs.paginationInfo.setPaginationData(onPaginationData)
        },
    },
}
</script>