<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Vuetable from 'vuetable-2/src/components/Vuetable.vue'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination.vue'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue'

import Layout from '@layouts/main.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import vueTableFormatter from '@src/mixins/vuetableFormatters'
import BusinessUnitCheckService from '@src/services/BusinessUnitCheckService'
import businessUnitCheckFields from '@src/fields/businessUnitCheckFields'
import multiSelectWithService from '@src/components/multiSelectWithService'
import filterVuetable from '@src/mixins/filterVuetable'
import ExportButton from '@/src/components/ExportButton.vue'

const i18nKey = 'BUSINESS_UNITS_CHECK'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        PageHeader,
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        DatePicker,
        multiSelectWithService,
        ExportButton,
    },

    mixins: [
        pagination,
        swalFeedback,
        vueTableFormatter,
        businessUnitCheckFields,
        filterVuetable,
    ],
    data() {
        return {
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modalIsEdit: false,
            additionalParameters: {
                with: ['user', 'businessUnit', 'pointOfSale'],
            },
            items: [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.buscheck',
                        modifier: 2,
                    }),
                    active: true,
                },
            ],
            data: [],
            perPage: 10,
            css: {
                pagination: {
                    activeClass: 'btn-primary text-white',
                    pageClass: 'btn btn-rounded',
                    linkClass: 'btn',
                    icons: {
                        prev: '',
                        next: '',
                    },
                },
            },

            loadingEdit: [],
            filtersVisible: false,
            filters: {
                dateTimeIn: {},
                dateTimeOut: {},
                user: [],
                business_unit: [],
                point_of_sale: [],
            },
        }
    },
    computed: {
        i18nNewCheck() {
            return this.getI18n(i18nKey, 'BUTTONS.new_buscheck')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nPos() {
            return this.getI18n('POS', 'TITLES.pos')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
    },
    watch: {
        filtersVisible(visible) {
            visible && this.cleanFilter()
        },
    },
    methods: {
        fetch(url, params) {
            return BusinessUnitCheckService.fetchVuetable(url, params)
        },
        i18nCheck(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.buscheck',
                modifier: modifier,
            })
        },
        cleanFilter() {
            Object.assign(this.filters, {
                dateTimeIn: {},
                dateTimeOut: {},
                user: [],
                point_of_sale: [],
                business_unit: [],
            })

            this.additionalParameters = {
                with: ['user', 'businessUnit', 'pointOfSale'],
            }
            this.refreshTable()
        },

        createAdditionalParameters() {
            return this.formatParameters({
                with: ['user', 'businessUnit', 'pointOfSale'],
                user_id: this.filters.user.map((unit) => unit.id),
                point_of_sale_id: this.filters.point_of_sale.map(
                    (pos) => pos.id
                ),
                business_unit_id: this.filters.business_unit.map(
                    (pos) => pos.id
                ),
                datetime_in: this.formatFilterDate(this.filters.dateTimeIn),
                datetime_out: this.formatFilterDate(this.filters.dateTimeOut),
            })
        },

        filter() {
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        refreshTable() {
            this.$nextTick(() => {
                this.$refs.vuetable.refresh()
            })
        },
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.buscheck',
                modifier: 2,
            }),
        }
    },
}
</script>

<template>
    <Layout>
        <PageHeader :title="i18nCheck(2)" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6 mb-3 form-inline mt-1"> </div>

                            <div class="col-sm-6 mb-3">
                                <div class="text-sm-right">
                                    <export-button
                                        v-if="$can('CREATE', 'EXPORTJOB')"
                                        type="business_unit_check"
                                        :create-parameters="
                                            createAdditionalParameters
                                        "
                                    />
                                    <b-button
                                        v-b-toggle.collapse
                                        variant="primary"
                                        class="mr-1 mt-1"
                                    >
                                        {{
                                            getI18nModified({
                                                prefix: i18nCommon,
                                                suffix: 'filter',
                                                modifier: 2,
                                            })
                                        }}
                                        <i
                                            v-if="filtersVisible"
                                            class="fa fa-angle-down"
                                        ></i>
                                        <i v-else class="fa fa-angle-up"></i>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <b-collapse id="collapse" v-model="filtersVisible">
                            <b-card no-body>
                                <h4 class="card-title">{{
                                    getI18nModified({
                                        prefix: 'COMMON',
                                        suffix: 'filter',
                                        modifier: 2,
                                    })
                                }}</h4>
                                <b-card-body class="pt-0">
                                    <b-row>
                                        <b-col>
                                            <b-form-group
                                                :label="i18nPos"
                                                label-for="filter-point-of-sale"
                                            >
                                                <multiSelectWithService
                                                    :id="'filter-point-of-sale'"
                                                    ref="pointOfSaleMultiselect"
                                                    v-model="
                                                        filters.point_of_sale
                                                    "
                                                    :service="'pos'"
                                                    :searchable="true"
                                                    :multiple="true"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                :label="i18nUser"
                                                label-for="filter-user"
                                            >
                                                <multiSelectWithService
                                                    :id="'filter-user'"
                                                    ref="userMultiselect"
                                                    v-model="filters.user"
                                                    :service="'users'"
                                                    :searchable="true"
                                                    :multiple="true"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                :label="i18nBusinessUnit"
                                                label-for="filter-business-unit"
                                            >
                                                <multiSelectWithService
                                                    :id="'filter-business-unit'"
                                                    ref="businessUnitMultiselect"
                                                    v-model="
                                                        filters.business_unit
                                                    "
                                                    :service="'business-units'"
                                                    :searchable="true"
                                                    :multiple="true"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="6">
                                            <b-form-group
                                                :label="
                                                    getI18n(
                                                        i18nCommon,
                                                        'datetime_in'
                                                    )
                                                "
                                                label-for="filter-started-at-date-time"
                                            >
                                                <div class="input-bar d-flex">
                                                    <date-picker
                                                        id="filter-started-at-date-time"
                                                        ref="filterCreatedAtDate"
                                                        v-model="
                                                            filters.dateTimeIn
                                                        "
                                                        :locale="
                                                            $i18n.locale.toLowerCase()
                                                        "
                                                        mode="range"
                                                        class="input-group"
                                                        :input-props="{
                                                            class:
                                                                'form-control',
                                                            placeholder: getI18n(
                                                                i18nCommon,
                                                                'datetime_in'
                                                            ),
                                                        }"
                                                        :popover="{
                                                            positionFixed: true,
                                                        }"
                                                    ></date-picker>
                                                    <button
                                                        class="btn btn-danger"
                                                        type="button"
                                                        @click="
                                                            filters.dateTimeIn = null
                                                        "
                                                        >{{
                                                            getI18n(
                                                                'COMMON',
                                                                'clean'
                                                            )
                                                        }}</button
                                                    >
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group
                                                :label="
                                                    getI18n(
                                                        i18nCommon,
                                                        'datetime_out'
                                                    )
                                                "
                                                label-for="filter-ended-at-date-time"
                                            >
                                                <div class="input-bar d-flex">
                                                    <date-picker
                                                        v-model="
                                                            filters.dateTimeOut
                                                        "
                                                        :locale="
                                                            $i18n.locale.toLowerCase()
                                                        "
                                                        mode="range"
                                                        class="input-group"
                                                        :input-props="{
                                                            class:
                                                                'form-control',
                                                            placeholder: getI18n(
                                                                i18nCommon,
                                                                'datetime_out'
                                                            ),
                                                        }"
                                                        :popover="{
                                                            positionFixed: true,
                                                        }"
                                                    ></date-picker>
                                                    <button
                                                        class="btn btn-danger"
                                                        type="button"
                                                        @click="
                                                            filters.dateTimeOut = null
                                                        "
                                                        >{{
                                                            getI18n(
                                                                'COMMON',
                                                                'clean'
                                                            )
                                                        }}</button
                                                    >
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-button
                                        variant="primary"
                                        class="float-right"
                                        @click="filter"
                                        >{{
                                            getI18nModified({
                                                prefix: 'ACTION',
                                                suffix: 'filter',
                                                modifier: 2,
                                            })
                                        }}</b-button
                                    >
                                    <b-button
                                        variant="warning"
                                        class="float-right mr-1"
                                        type="button"
                                        @click="cleanFilter"
                                        >{{
                                            getI18n('COMMON', 'clean')
                                        }}</b-button
                                    >
                                </b-card-body>
                            </b-card>
                        </b-collapse>
                        <div class="table-responsive">
                            <vuetable
                                ref="vuetable"
                                :per-page="perPage"
                                :fields="businessUnitCheckFields"
                                :api-mode="true"
                                :http-fetch="fetch"
                                :append-params="additionalParameters"
                                :sort-order="[
                                    {
                                        field: 'datetime_in',
                                        direction: 'desc',
                                    },
                                ]"
                                data-path="data"
                                pagination-path="meta"
                                class="table table-centered table-striped"
                                @vuetable:pagination-data="onPaginationData"
                            ></vuetable>
                        </div>
                        <!--end table-->

                        <!--pagination-->
                        <div class="row">
                            <div class="col sm-12 col md-5">
                                <vuetable-pagination-info
                                    ref="paginationInfo"
                                    info-class="pagination-info"
                                    :info-template="i18nPagination()"
                                ></vuetable-pagination-info>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <vuetable-pagination
                                    ref="pagination"
                                    class="pagination pagination-rounded justify-content-end"
                                    @vuetable-pagination:change-page="
                                        onChangePage
                                    "
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
