<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-blue mr-1"
        href="javascript: void(0);"
        :disabled="loading"
        @click="handleOk">
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nType"
            :operation="'export'"
        />
        <b-spinner v-if="loading" small class="mr-1"/>
        <i v-else class="fe-download mr-1"></i>
        {{ title === '' ? getI18n('COMMON', 'export') : title }}
    </button>
</template>

<script>
import ExportJobService from '@src/services/ExportJobService'
import swalFeedback from '@src/mixins/swalFeedback'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'

export default {
    components: {
        ConfirmationFormModal,
    },
    mixins: [swalFeedback],
    props: {
        title: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            required: true,
        },
        createParameters: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
        }
    },
    computed: {
        i18nType() {
            return this.getI18n('EXPORT_JOBS', `TYPES.${this.type}`)
        }
    },
    methods: {
        i18nExportJob(modifier) {
            return this.getI18nModified({
                prefix: 'EXPORT_JOBS.TITLES',
                suffix: 'export',
                modifier: modifier,
            })
        },
        async handleOk() {
            if (!this.loading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return
            }

            this.loading = true
            let parameters = await this.createParameters()

            parameters = {
                ...parameters,
                export_type: this.type,
                language: 'pt_BR',
            }
            const createResponse = await ExportJobService.create(
                parameters
            )
                .then((response) => response)
                .catch((error) => {
                    return error
                })

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$router.push({ name: 'exportJobs', params: { newJobCreated: true } })
            } else {
                this.negativeFeedback(this.getI18n('RESPONSES', 'create_not_possible'), createResponse.data.message)
            }

            this.loading = false
        },
    },
}
</script>
